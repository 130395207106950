<template>
    <div class="sys-fun">
        <page-title :title="'功能效果管理'">配置修改所有的功能效果</page-title>
        <div class="fun-area">
            <div class="fun-table">
                <div>
                    <div>
                        功能清单
                    </div>
                    <el-table
                        :data="tableData"
                        stripe
                        style="width: 100%">
                        <el-table-column
                        label="序号"
                        width="50"
                        type="index"
                        align="center">
                        </el-table-column>
                        <el-table-column
                        prop="funIcon"
                        label="图标"
                        align="center"
                        width="60">
                        <template slot-scope="scope">
                            <el-image :src="scope.row.funIcon" style="width:32px;height32px;"></el-image>
                        </template>
                        </el-table-column>
                        <el-table-column
                        prop="funName"
                        label="功能名称"
                        width="100"
                        align="center">
                        </el-table-column>
                        <el-table-column
                        prop="funClass"
                        align="center"
                        width="100"
                        label="所属分类">
                        </el-table-column>
                        <el-table-column
                        prop="isOpen"
                        label="是否可用"
                        align="center"
                        width="80">
                        <template slot-scope="scope">
                            <el-switch
                                v-model="scope.row.isopen"
                                active-color="#13ce66"
                                inactive-color="#ff4949">
                            </el-switch>
                        </template>
                        </el-table-column>
                        <el-table-column
                        prop="funDesc"
                        header-align="center"
                        label="功能说明">
                        </el-table-column>
                        <el-table-column
                        align="center"
                        label="操作"
                        width="100">
                        <template slot-scope="scope">
                            <el-button type="text" @click="funEdit(scope.row)">编辑</el-button>
                            <el-button type="text" @click="funDel(scope.row.id)">删除</el-button>
                        </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="fun-edit">
                <div>
                    <div>
                        功能编辑
                    </div>
                    <el-form ref="form" :model="form" label-width="120px" size="small" label-position="top">
                        <el-form-item label="功能名称" prop="funName" :rules="{ required: true, message: '功能名称不能为空', trigger: 'blur' }">
                            <el-input v-model="form.funName" placeholder="建议4~6个字"></el-input>
                        </el-form-item>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="所属分类" prop="funClass" :rules="{ required: true, message: '必须选择分类', trigger: 'blur' }">
                                    <el-select style="width:100%;" v-model="form.funClass">
                                        <el-option label="开场设置" value="开场设置"></el-option>
                                        <el-option label="留言互动" value="留言互动"></el-option>
                                        <el-option label="现场游戏" value="现场游戏"></el-option>
                                        <el-option label="互动抽奖" value="互动抽奖"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="是否可用" prop="isopen">
                                    <el-switch
                                        v-model="form.isopen"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949">
                                    </el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="功能描述" prop="funDesc" :rules="{ required: true, message: '请填写功能说明', trigger: 'blur' }">
                            <el-input v-model="form.funDesc"></el-input>
                        </el-form-item>
                        <el-form-item label="配置页面" prop="funConfigPath">
                            <el-input v-model="form.funConfigPath"></el-input>
                        </el-form-item>
                        <el-form-item label="备注说明" prop="remark">
                            <el-input type="textarea" :rows="3" v-model="form.remark"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="resetForm">重置</el-button>
                            <el-button type="success" @click="save">保存功能</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"FunManage",
    data(){
        return{
            tableData:[],
            form:{
                id: null,
                funName: "",
                funClass: "",
                funConfigPath: null,
                funDesc: "",
                funIcon: "",
                isopen: 0,
                remark: null
            }
        }
    },
    created(){
        this.initList();
    },
    methods:{
        initList(){
            const that = this;
            that.request.get("sysfun/list").then(res=>{
                that.tableData = res.data;
            });
        },
        save(){
            const that = this;
            console.log(that.form);
            that.form.isopen = 1;
            that.$refs['form'].validate((valid)=>{
                if(valid){
                    that.request.post("sysfun/saveOrUpdate",that.form).then(res=>{
                        if(res.code===200){
                            that.initList();//刷新列表
                            that.resetForm();//重置表单
                        }
                    });
                }
            });
        },
        resetForm(){
            this.$refs['form'].resetFields();
            this.form.id=null;
        },
        funDel(id){
            const that = this;
            that.$confirm('此操作将永久删除该功能, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                that.request.get("sysfun/delete",{id:id}).then(res=>{
                    if(res.code===200){
                        that.initList();
                    }
                })
            }).catch(() => {
            });
        },
        funEdit(obj){
            this.form = JSON.parse(JSON.stringify(obj));
        }
    }
}
</script>
<style lang="less" scoped>
.sys-fun{
    .fun-area{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .fun-table{
            width: 49%;
            background-color: #ffffff;
            >div{
                padding: 20px;
            }
        }
        .fun-edit{
            width: 49%;
            background-color: #ffffff;
            >div{
                padding: 20px;
            }
        }
    }

    /deep/ .el-table td{
        padding: 2px 0px;
    }
}
</style>
